<template>
  <div>
    <b-sidebar
      v-model="sidebarActive"
      right
      bg-variant="white"
      spacer
      backdrop
      shadow
    >
      <b-container>
        <template>
          <div class="mt-6 text-center">
            <h4>{{ !this.roleId ? $t('ADD NEW ') : $t('UPDATE ') }} {{ $t('ROLE ') }}</h4>
          </div>
          <div class="mt-5">
            <b-form-group
              label-for="hi-first-name"
              label-cols-md="12"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  v-model="selectedRolePermissions.name"
                  :placeholder="$t('Role Name')"
                />
              </b-input-group>
            </b-form-group>
            <vs-alert
              v-show="errors.name"
              v-if="errors.name"
              color="danger"
              active="true"
            >
              {{ $t(errors.name[0]) }}
            </vs-alert>
          </div>
          <div
            class="accordion"
            role="tablist"
          >
            <b-card
              v-for="(permissionGroup, index) in permissions"
              :key="index"
              no-body
              class="mb-1"
            >
              <b-card-header
                header-tag="header"
                class="p-1"
                role="tab"
              >
                <b-button
                  v-b-toggle="index+'_b_collapse'"
                  block
                  variant="gradient-primary"
                >
                  {{ $t(index) }}
                </b-button>
              </b-card-header>
              <b-collapse
                :id="index+'_b_collapse'"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-form-group
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-checkbox-group
                      id="checkbox-group-1"
                      v-model="selectedRolePermissions.permissions"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-1"
                    ><b-row>
                      <b-col
                        v-for="permission in permissionGroup"
                        :key="permission.id"
                        cols="12"
                      >
                        <b-form-checkbox
                          :value="permission.id"
                        >{{ $t(permission.name) }}
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </template>
      </b-container>
      <div
        slot="footer"
        class="d-flex flex-wrap justify-content-around items-center pt-1"
      >
        <b-button
          icon-after
          class="mb-2"
          variant="gradient-success"
          @click="saveRole()"
        >
          {{ $t('Save') }}
        </b-button>
        <b-button
          icon-after
          class="mb-2"
          variant="gradient-danger"
          @click="sidebarActive = false"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  props: {
    roleId: {
      default: null,
      type: [String, Number],
    },
    isSidebarActive: {
      default: null,
      type: [String, Number, Boolean],
    },
  },
  data() {
    return {
      selectedRolePermissions: {
        name: '',
        permissions: [],
      },
      sidebarActive: false,
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.60,
      },
      permissionsModels: [],
      errors: [],
      localRoleId: '',
    }
  },
  computed: {
    permissions() {
      return this.$store.getters['role/groupedPermissions']
    },
    rolePermissions() {
      return this.$store.getters['role/rolePermissions']
    },
    scrollbarTag() { return this.$store.getters.scrollbarTag },

  },
  watch: {
    sidebarActive(val) {
      if (!val) {
        setTimeout(() => {
          this.$store.dispatch('role/initState')
          this.$emit('close-sidebar')
        }, 1)
      }
    },
    isSidebarActive(val) {
      this.sidebarActive = val
    },
    roleId(val) {
      this.localRoleId = val
      if (this.localRoleId) {
        this.$store.dispatch('role/getRolePermissions', this.localRoleId)
      }
    },
    permissions() {
      this.permissionsModels = Object.keys(this.permissions)
    },
    rolePermissions(val) {
      this.selectedRolePermissions = { ...val }
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      const args = ''
      this.$store.dispatch('role/getGroupedPermissions', args)
      // setTimeout(() => {
      //
      // }, 1)
    },
    saveRole() {
      if (!this.roleId) {
        this.$store.dispatch('role/storeRole', this.selectedRolePermissions).then(() => {
          this.sidebarActive = false
        }).catch(error => {
          if (error.response.data.errors) this.errors = error.response.data.errors
        })
      } else {
        this.$store.dispatch('role/updateRole', this.selectedRolePermissions).then(() => {
          this.sidebarActive = false
        }).catch(error => {
          if (error.response.data.errors) this.errors = error.response.data.errors
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
