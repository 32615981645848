<template>
  <div>
    <b-modal
      v-model="popupActive"
      :title="$t('Filter with')"
      hide-footer
    >
      <h3 />
      <vs-tabs v-model="activeTab">
        <vs-tab :label="$t('Permissions')">
          <div>
            <vs-select
              v-model="selectedPermissions"
              :placeholder="$t('select Permissions')"
              class="selectExample mt-2 py-1 mb-2"
              style="width: 100%"
              multiple
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            >
              <vs-select-item
                v-for="(item,index) in permissions"
                :key="index"
                :value="item.id"
                :text="$t(item.name)"
              />
            </vs-select>
          </div>
        </vs-tab>
        <vs-tab :label="$t('Models')">
          <div>
            <vs-select
              v-model="selectedModels"
              :placeholder="$t('select Permissions')"
              class="selectExample mt-5 py-1 mb-2"
              style="width: 100%"
              multiple
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            >
              <vs-select-item
                v-for="(item,index) in models"
                :key="index"
                :value="item"
                :text="$t(item)"
              />
            </vs-select>
          </div>
        </vs-tab>
      </vs-tabs>
      <b-button
        variant="gradient-primary"
        @click="filter()"
      >
        {{ $t('filter') }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'FilterRoles',
  data() {
    return {
      popupActive: true,
      selectedPermissions: [],
      selectedModels: [],
      activeTab: 0,
    }
  },
  computed: {
    permissions() {
      return this.$store.getters['role/permissions']
    },
    models() {
      return this.$store.getters['role/models']
    },
  },
  watch: {
    popupActive(val) {
      if (!val) {
        setTimeout(() => {
          this.$emit('close-popup')
        }, 1)
      }
    },
    activeTab() {
      this.initVals()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('role/getPermissions')
      this.$store.dispatch('role/getModels')
    },
    initVals() {
      this.selectedModels = []
      this.selectedPermissions = []
    },
    filter() {
      this.$emit('toggle-filter')
      this.popupActive = false
      if (this.activeTab === 0) {
        this.$store.dispatch('role/filterByPermissions', this.selectedPermissions)
      } else {
        this.$store.dispatch('role/filterByModels', this.selectedModels)
      }
    },
  },
}
</script>

<style scoped>
  .test div {
    overflow: visible !important;
  }
</style>
