<template>
  <div>
    <view-permissions
      v-model="permissionsPopup"
      :permission-args="permissionArgs"
      @close-popup="closePopUp"
    />
    <sidebar-role
      :is-sidebar-active="newRoleSidebar"
      :role-id="roleId"
      @close-sidebar="toggleDataSidebar"
    />
    <filter-roles
      v-if="filterPopup"
      @close-popup="toggleFilterPopup"
      @toggle-filter="toggleFilter"
    />

    <vs-row class="pt-6 my-2">
      <vs-col
        vs-w="6"
        vs-type="flex"
      >
        <vs-button
          v-if="isFiltered"
          color="danger"
          type="border"
          class="mr-4"
          @click="toggleFilter(false)"
        >
          <font-awesome-icon
            svg-classes="h-4 w-4"
            class="mr-2"
            :icon="['fas', 'times']"
          />
          {{ $t('Clear Filter') }}
        </vs-button>
        <vs-button
          color="primary"
          type="border"
          class="mr-4"
          @click="toggleFilterPopup"
        >
          <font-awesome-icon
            svg-classes="h-4 w-4"
            class="mr-2"
            :icon="['fas', 'filter']"
          />
          {{ $t('Filter') }}
        </vs-button>
        <b-button
          variant="gradient-success"
          icon-pack="feather"
          icon-after
          icon="icon-plus"
          @click="addNewRole()"
        > {{ $t('new role') }}
        </b-button>
      </vs-col>
      <vs-col
        vs-w="6"
        vs-type="flex"
        vs-justify="flex-end"
      >
        <vs-button
          type="gradient"
          color="danger"
          @click="$router.push({name: 'trashed_roles'}).catch(() => {})"
        >{{ $t('Trash') }}
          <font-awesome-icon :icon="['far', 'trash-alt']" />
        </vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="pt-12">
      <vs-col>
        <template>
          <div
            id="data-list-list-view"
            class="data-list-container"
          >
            <vs-table
              search
              max-items="5"
              pagination
              :data="roles"
            >
              <template slot="header">
                <h3>{{ $t('Roles') }}</h3>
              </template>
              <template slot="thead">
                <vs-th>{{ $t('Id') }}</vs-th>
                <vs-th>{{ $t('Role') }}</vs-th>
                <vs-th>{{ $t('Permissions') }}</vs-th>
                <vs-th>{{ $t('Models') }}</vs-th>
                <vs-th>{{ $t('Actions') }}</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr
                  v-for="(tr, indextr) in data"
                  :key="indextr"
                >
                  <vs-td :data="tr">
                    <vs-chip color="primary">
                      {{ tr.id }}
                    </vs-chip>
                  </vs-td>
                  <vs-td :data="tr">
                    {{ $t(tr.name) }}
                  </vs-td>
                  <vs-td :data="tr">
                    <vs-button
                      v-if="tr.permission_count>0"
                      color="primary"
                      type="border"
                      @click="openPopUp(tr.id)"
                    >{{ $t('Granted permissions') }}
                    </vs-button>
                    <vs-button
                      v-else
                      icon-pack="feather"
                      icon-after
                      icon="icon-plus"
                      color="warning"
                      type="border"
                      @click="editRole(tr.id)"
                    >{{ $t('Add permissions') }}
                    </vs-button>
                  </vs-td>
                  <vs-td :data="tr">
                    <vs-button
                      v-for="model in tr.models"
                      :key="model.index"
                      type="line"
                      class="m-1"
                      @click="openPopUp(tr.id,model)"
                    >{{ $t(model) }}
                      <font-awesome-icon :icon="['fas', 'list']" />
                    </vs-button>
                  </vs-td>
                  <vs-td :data="tr">
                    <a
                      class="mx-1"
                      href="javascript:void(0)"
                      @click="editRole(tr.id)"
                    >
                      <font-awesome-icon
                        color="rgb(var(--vs-success))"
                        :icon="['fas', 'edit']"
                      />
                    </a>
                    <a
                      class="mx-1"
                      href="javascript:void(0)"
                      @click="removeAlert(tr.id)"
                    >
                      <font-awesome-icon
                        color="rgb(var(--vs-danger))"
                        :icon="['fas', 'trash-alt']"
                      />
                    </a>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </template>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>

import ViewPermissions from './ViewPermissions.vue'
import SidebarRole from './SidebarRole.vue'
import FilterRoles from './FilterRoles.vue'

export default {

  name: 'Permissions',
  components: { FilterRoles, ViewPermissions, SidebarRole },
  data() {
    return {
      isFiltered: false,
      isNewUser: false,
      permissionsPopup: false,
      filterPopup: false,
      permissionArgs: [],
      newRoleSidebar: false,
      roleId: '',
    }
  },
  computed: {
    roles() {
      return this.$store.getters['role/roles']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('role/getRoles')
    },
    deleteRole() {
      this.$store.dispatch('role/deleteRole', this.roleId).then(() => {
        this.roleId = ''
      })
    },
    addNewRole() {
      this.roleId = ''
      this.toggleDataSidebar(true)
    },
    editRole(roleId) {
      this.roleId = roleId
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val = false) {
      this.newRoleSidebar = val
      if (val === false) {
        this.roleId = null
      }
    },
    openPopUp(...args) {
      this.permissionArgs = args
      this.permissionsPopup = true
    },
    closePopUp() {
      this.permissionsPopup = false
    },
    toggleFilterPopup() {
      this.filterPopup = !this.filterPopup
    },
    toggleFilter(val = true) {
      this.isFiltered = val
      if (val === false) {
        this.getData()
      }
    },
    removeAlert(val) {
      this.roleId = val
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: this.$t('Are you sure you want to delete this element'),
        accept: this.deleteRole,
        acceptText: this.$t('Delete'),
        cancelText: this.$t('Cancel'),
      })
    },
  },
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }
  }
}
</style>
