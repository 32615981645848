<template>
  <div>
    <b-modal
      v-model="IsPermissionPopupActiveLocal"
      :title="$t('Permissions')"
      :ok-title="$t('ok')"
      ok-only
    >
      <b-container>
        <template>
          <div
            class="accordion"
            role="tablist"
          >
            <b-card
              v-for="(permissionGroup, index) in permissions"
              :key="index"
              no-body
              class="mb-1"
            >
              <b-card-header
                header-tag="header"
                class="p-1"
                role="tab"
              >
                <b-button
                  v-b-toggle="index+'_b_collapse'"
                  block
                  variant="gradient-primary"
                >
                  {{ $t(index) }}
                </b-button>
              </b-card-header>
              <b-collapse
                :id="index+'_b_collapse'"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-form-group>
                    <b-badge
                      v-for="permission in permissionGroup"
                      :key="permission.id"
                      pill
                      variant="primary"
                      class="mt-1 mr-1 badge-glow"
                    >{{ $t(permission.name) }}</b-badge>
                  </b-form-group>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </template>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'ViewPermissions',
  props: ['value', 'permissionArgs'],
  data() {
    return {
      permissionsModels: [],
      popupActive: true,
    }
  },
  computed: {
    permissions() {
      return this.$store.getters['role/groupedPermissions']
    },

    IsPermissionPopupActiveLocal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    permissions() {
      this.permissionsModels = Object.keys(this.permissions)
    },
    permissionArgs(val) {
      this.$store.dispatch('role/getGroupedPermissions', val)
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('role/getGroupedPermissions', this.permissionArgs)
    },
  },
}
</script>

<style scoped>

</style>
